import { useDispatch, useSelector } from 'react-redux';
import { Request } from 'src/helpers';
import { logoutPortal } from 'src/redux/slices/auth';
import { setPortalMeta } from 'src/redux/slices/portal';
import useNotif from './useNotif';
import { useNavigate } from 'react-router';

const usePortalRequest = (
  loadingOn = () => {},
  loadingOff = () => {},
  extraConfig = {}
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = useNotif();
  const { base_url, portal } = useSelector(state => state.auth);

  const { cdBiz = '', cdPortal = '', token: portalToken = null } = portal;

  const config = {
    headers: {
      'x-access-tokens': portalToken,
      'Content-Type': 'application/json'
    },
    ...extraConfig
  };

  const loadingDisplay = {
    display: loadingOn,
    hide: loadingOff
  };

  function handleError(error) {
    if (!error.isCancelled && error.error.status === 401) {
      dispatch(logoutPortal());
      dispatch(setPortalMeta({}));
      navigate(`/portal/${cdBiz}/${cdPortal}/login`);
      notify.info('You have been logged out. Please log in again.');
    }
  }

  function handleSuccess() {}

  const request = new Request(
    base_url,
    config,
    loadingDisplay,
    handleSuccess,
    handleError
  );

  return request;
};

export default usePortalRequest;
