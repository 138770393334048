import React, { useEffect } from 'react';
import { makeStyles, TextField, MenuItem, Box } from '@material-ui/core';
import APIRequest from 'src/helpers/APIRequest';
import { useSelector } from 'react-redux';
import useNotif from 'src/hooks/useNotif';
import SrchSub from 'src/components/SrchSub';
import useWarehouse from 'src/hooks/useWarehouse';
import { INVENTORY_TYPES } from 'src/constants';
const { STOCK_TRANSFER_BY_WH } = INVENTORY_TYPES;

const useStyles = makeStyles(theme => ({
  textField: {
    flex: 1
  }
}));

const SelectWarehouse = ({
  caption = 'Warehouse',
  selectedWarehouse = 0,
  ixSubWH = 0,
  sSubWH = '',
  showSubWH = false,
  ixSubTypeWH = 0,
  sSubTypeWH = '',
  autoSelectIfEmpty = false,
  onChangeWarehouse = val => null,
  onChangeSubwarehouse = val => null,
  containerProps = {},
  textFieldProps = {},
  subWarehouseProps = {},
  exclude = [],
  include = [],
  ixJCd = 0
}) => {
  const classNames = useStyles();
  const { error } = useNotif();
  const { loading, warehouses } = useWarehouse({
    include,
    exclude,
    ixJCd,
    selectedWarehouse
  });
  const { userToken, base_url } = useSelector(({ auth }) => auth);

  const handleChangeSubWarehouse = ({ ixSub, sSub }) => {
    onChangeSubwarehouse({
      ixSubAcc: ixSub,
      sSubAcc: sSub,
      show: true,
      ixSubType: ixSubTypeWH,
      sSubType: sSubTypeWH
    });
  };

  const handleChangeWarehouse = async value => {
    onChangeWarehouse(value);

    if (value === 0) {
      onChangeSubwarehouse({
        ixSubAcc: 0,
        sSubAcc: '',
        show: false,
        ixSubType: 0,
        sSubType: ''
      });
      return;
    }

    const { data, success } = await APIRequest({
      url: `${base_url}/setup/acc/${value}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': userToken
      }
    });

    if (!success) {
      error('Failed to fetch warehouse info.');
      return;
    }

    if (!data.ixSubType1) {
      onChangeSubwarehouse({
        ixSubAcc: 0,
        sSubAcc: '',
        show: false,
        ixSubType: 0,
        sSubType: ''
      });
      return;
    }

    onChangeSubwarehouse({
      ixSubAcc: 0,
      sSubAcc: '',
      show: true,
      ixSubType: data.ixSubType1,
      sSubType: data.sSubType1
    });
  };

  useEffect(() => {
    if (!autoSelectIfEmpty) return;

    if (selectedWarehouse === 0 && warehouses.length !== 0) {
      handleChangeWarehouse(warehouses[0].ixWH);
    }
  }, [warehouses, selectedWarehouse]);

  return (
    <Box display="flex" gridGap="1em" {...containerProps}>
      <TextField
        select
        label={caption}
        value={selectedWarehouse}
        onChange={e => handleChangeWarehouse(e.target.value)}
        variant="outlined"
        disabled={loading}
        fullWidth
        className={classNames.textField}
        {...textFieldProps}
      >
        {warehouses.map((data, i) => (
          <MenuItem value={data?.ixWH} key={i}>
            {data?.sWH}
          </MenuItem>
        ))}
      </TextField>

      {showSubWH && (
        <Box className={classNames.textField} {...subWarehouseProps}>
          <SrchSub
            ixSubType={ixSubTypeWH}
            sSubType={sSubTypeWH}
            showAdd={false}
            showEdit={false}
            selectedSub={sSubWH}
            placeholder={sSubTypeWH}
            handleSubSelectRoot={handleChangeSubWarehouse}
          />
        </Box>
      )}
    </Box>
  );
};

export default SelectWarehouse;
