import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
const INITIAL_STATE = {
  dateRange: {
    dt1: '',
    dt2: '',
    textValue: ''
  },
  activeJCd: [],
  activeWH: {},
  activeProdCat: {},
  activeProdSubCat: {},
  activeVariant: {},
  ixBrch_from: 0,
  ixBrch_to: 0,
  result: [],
  meta: {
    jcd: [],
    variations: [],
    wh: []
  },
  page: 1,
  limit: 100,
  showSN: false,
  showSN2: false,
  showBN: false,
  showExpDate: false,
  showProdDate: false,
  showCS: false,
  showSC: false,
  showAll: false,
  showCost: false,
  ixProdSubLink1: 0,
  sProdSubLink1: '',
  ixProdSubLink2: 0,
  sProdSubLink2: '',
  ixProdSubLink3: 0,
  sProdSubLink3: '',
  ixProdSubLink4: 0,
  sProdSubLink4: '',
  ixProdSubLink5: 0,
  sProdSubLink5: ''
};

const slice = createSlice({
  name: 'brTransfer',
  initialState: INITIAL_STATE,
  reducers: {
    storeActiveVariant(state, action) {
      state.activeVariant = action.payload;
    },
    storeDateRange(state, action) {
      state.dateRange = action.payload;
    },
    storeActiveJCd(state, action) {
      state.activeJCd = action.payload;
    },
    storeActiveWH(state, action) {
      state.activeWH = action.payload;
    },
    storeActiveProdCat(state, action) {
      state.activeProdCat = action.payload;
    },
    storeActiveProdSubCat(state, action) {
      state.activeProdSubCat = action.payload;
    },
    storeBranchFrom(state, action) {
      state.ixBrch_from = action.payload;
    },
    storeBranchTo(state, action) {
      state.ixBrch_to = action.payload;
    },
    storeResult(state, action) {
      const { items, showAll } = action.payload;
      state.result = items.map(item => ({ ...item, key_id: uuid() }));
      state.showCS = items.some(item => item.libQtyCS > 0);
      state.showSC = items.some(item => item.libQtySC > 0);
      state.showSN = items.some(item => {
        return typeof item?.SN !== 'undefined' && item.SN !== '';
      });
      state.showSN2 = items.some(item => {
        return typeof item?.SN2 !== 'undefined' && item.SN2 !== '';
      });
      state.showBN = items.some(item => {
        return item?.BN ?? '' !== '';
      });
      state.showExpDate = items.some(item => {
        return !(
          item?.ExpDate === null ||
          typeof item?.ExpDate === 'undefined' ||
          item?.ExpDate === ''
        );
      });
      state.showProdDate = items.some(item => {
        return !(
          item?.ProdDate === null ||
          typeof item?.ProdDate === 'undefined' ||
          item?.ProdDate === ''
        );
      });
      state.showCost = items.some(item => item.hasOwnProperty('cost'));
      state.showAll = showAll;

      if (showAll) state.page = 1;
    },
    setMeta(state, action) {
      state.meta = action.payload;
    },
    setProdSubLink1(state, action) {
      state.ixProdSubLink1 = action.payload.ixProdSubLink1;
      state.sProdSubLink1 = action.payload.sProdSubLink1;
    },
    setProdSubLink2(state, action) {
      state.ixProdSubLink2 = action.payload.ixProdSubLink2;
      state.sProdSubLink2 = action.payload.sProdSubLink2;
    },
    setProdSubLink3(state, action) {
      state.ixProdSubLink3 = action.payload.ixProdSubLink3;
      state.sProdSubLink3 = action.payload.sProdSubLink3;
    },
    setProdSubLink4(state, action) {
      state.ixProdSubLink4 = action.payload.ixProdSubLink4;
      state.sProdSubLink4 = action.payload.sProdSubLink4;
    },
    setProdSubLink5(state, action) {
      state.ixProdSubLink5 = action.payload.ixProdSubLink5;
      state.sProdSubLink5 = action.payload.sProdSubLink5;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    clearData(state, _) {
      state.result = [];
    },
    resetState() {
      return INITIAL_STATE;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const brTransferReducer = slice.reducer;
export const {
  storeDateRange,
  storeActiveJCd,
  storeActiveWH,
  storeActiveVariant,
  storeActiveProdCat,
  storeActiveProdSubCat,
  storeBranchFrom,
  storeBranchTo,
  setMeta,
  setProdSubLink1,
  setProdSubLink2,
  setProdSubLink3,
  setProdSubLink4,
  setProdSubLink5,
  setPage,
  setLimit,
  clearData,
  storeResult,
  setSize,
  resetState,
  restore,
  clear
} = slice.actions;
export default brTransferReducer;
