import { makeStyles } from '@material-ui/core';

const CELL_X_PADDING = 20;

const useStyles = makeStyles(theme => ({
  cellDense: {
    padding: 0,
    paddingLeft: CELL_X_PADDING,
    paddingRight: CELL_X_PADDING,
    height: 46,
    minWidth: 100,
    maxWidth: 100,
    boxSizing: 'border-box',
    borderBottom: '1px solid #C4C4C4',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:focus': {
      outline: 'none',
      border: '1px solid ' + theme.palette.primary.main
    },
    transition: 'box-shadow 0.3s ease',
    verticalAlign: 'middle',
    backgroundColor: 'inherit'
  },
  header: {
    padding: CELL_X_PADDING,
    paddingTop: 2,
    paddingBottom: 2,
    verticalAlign: 'bottom',
    height: 52,
    verticalAlign: 'middle',
    minWidth: 100,
    maxWidth: 100,
    boxSizing: 'border-box',
    borderBottom: '3px solid #C4C4C4',
    transition: 'box-shadow 500ms ease'
  },
  alternatingRow: {
    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.palette.background.default
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: theme.palette.background.paper
    }
  }
}));

export default useStyles;
