import { isNumber, round } from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

class Earning {
  id;
  ixEmp;
  sEmp;
  ixEarn;
  sEarn;
  Amt;
  Qty;
  Rate;
  Particulars;
  tsDate;

  constructor({
    id = uuid(),
    ixEmp = 0,
    sEmp = '',
    ixEarn = 0,
    sEarn = '',
    Amt = 0,
    Qty = 0,
    Rate = 0,
    Particulars = '',
    tsDate = moment().format('YYYY-MM-DDTHH:mm:ss')
  }) {
    this.id = id;
    this.ixEmp = ixEmp;
    this.sEmp = sEmp;
    this.ixEarn = ixEarn;
    this.sEarn = sEarn;
    this.Amt = round(Amt, 4);
    this.Qty = Qty;
    this.Rate = Rate;
    this.Particulars = Particulars;
    this.tsDate = tsDate;
  }

  update(data = {}) {
    Object.keys(data).forEach(key => {
      if (key in this)
        this[key] = isNumber(data[key]) ? round(data[key], 4) : data[key];
    });
  }
}

export default Earning;
