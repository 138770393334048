import { isArrayLikeObject } from 'lodash';
import React from 'react';
import MaskedInput from 'react-text-mask';
import DebounceTextField from '../DebounceTextField';

const regexPatterns = ['\\d', '\\w', '\\s', '\\D', '\\W', '\\b', '\\B'];

function convertToRegex(str) {
  try {
    return regexPatterns.includes(str) ? new RegExp(str) : str;
  } catch (e) {
    return str;
  }
}

function generateMask(mask) {
  if (!mask && !isArrayLikeObject(mask)) return false;

  return mask.map(convertToRegex);
}

const TextMask = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={other.mask}
      placeholderChar="_"
      keepCharPositions
      showMask
      guide
    />
  );
};

const CustomMaskedInput = ({ data, onChangeField }) => {
  const mask = generateMask(data?.mask);

  return (
    <DebounceTextField
      label={data?.label || ''}
      variant="outlined"
      InputProps={{
        inputComponent: TextMask
      }}
      onChange={onChangeField}
      value={data?.value || ''}
      inputProps={{ mask }}
      InputLabelProps={{
        shrink: true
      }}
      name={data.fld}
      {...data.rest}
    />
  );
};

export default CustomMaskedInput;
