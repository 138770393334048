import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'dashboardCache',
  initialState: {},
  reducers: {
    setCache(state, action) {
      state[action.payload.key] = action.payload.value;
    },
    clear() {
      return {};
    },
    restore(_, action) {
      return action.payload || {};
    },
    override(_, action) {
      return action.payload || {};
    }
  }
});

const dashboardCacheReducer = slice.reducer;

export const { restore, clear, setCache } = slice.actions;
export default dashboardCacheReducer;
