const registry = {
  class: '/registry/student/class',
  statsMeta: '/registry/stats/meta',
  users: '/registry/user',
  selectBiz: '/select-biz',
  bizInfo: ixBiz => `/registry/biz/${ixBiz}`,
  bizStats: ixBiz => `/registry/biz/${ixBiz}/info`,
  assignUser: ixBiz => `/registry/biz/${ixBiz}/users`,
  updateClass: ({ ixClass }) => '/registry/student/class/' + ixClass,
  classStudents: ({ ixClass }) => `/registry/student/class/${ixClass}/students`,
  addClassStudent: ({ ixClass }) =>
    `/registry/student/class/${ixClass}/students/add`,
  removeClassStudent: ({ ixClass, ixStudent }) =>
    `/registry/student/class/${ixClass}/students/remove/${ixStudent}`,
  consultants: '/registry/user/consultants',
  checkLayout: '/setup/check-layouts/',
  checkLayoutLogs: bankCode => `/setup/check-layouts/${bankCode}/logs`,
  announcements: '/globals/announcements',
  agency: {
    bizInfo: ixBiz => '/admin/agency/biz/' + ixBiz,
    bizItems: ixBiz => '/admin/agency/biz/' + ixBiz + '/biz-items',
    activeUsers: ixBiz => '/admin/agency/biz/' + ixBiz + '/users/active',
    inactiveUsers: ixBiz => '/admin/agency/biz/' + ixBiz + '/users/inactive'
  },
  sandbox: ixBiz => `/registry/biz/${ixBiz}/sandbox`,
  sandboxLogs: ixBiz => `/registry/biz/${ixBiz}/sandbox/logs`,
  advancedSettings: ixBiz => `/registry/biz/${ixBiz}/json-settings`
};

const trans = {
  list: uuid => `/trans/jcd/${uuid}`,
  transactionMeta: ({ uuid }) => `/trans/jcd/${uuid}/new`,
  transactionTemplates: ({ uuid }) => `/trans/jcd/${uuid}/templates`,
  saveTransaction: ({ uuid }) => `/trans/jcd/${uuid}/save`,
  savePayroll: ({ uuid }) => `/pr/${uuid}/save`,
  reqBalSearch: '/trans/search/req/bal',
  reqBalDetails: '/trans/get/req/bal/details',
  reqBalLedger: ixReqBalSrc =>
    '/trans/get/req/bal/details?ixReqBalSrc=' + ixReqBalSrc,
  savePaysked: jid => '/trans/jv/' + jid + '/reqbalsked',
  paymentSchedule: jid => '/trans/jv/' + jid + '/reqbalsked',
  payroll: {
    addEmployee: '/pr/trans',
    meta: ({ jid }) => `/pr/${jid}`,
    addEarnings: '/pr/trans/earn',
    addDeductions: '/pr/trans/ded',
    addMultipleEmployee: '/pr/trans/many',
    addMultipleEarnings: '/pr/trans/earn/many',
    addMultipleDeductions: '/pr/trans/ded/many',
    bio: jid => `/pr/${jid}/bio`,
    deleteDeduction: '/pr/trans/ded',
    deleteEarning: '/pr/trans/earn',
    deleteEmployee: '/pr/trans',
    log: jid => '/pr/' + jid + '/logs',
    unlinkLeaves: ixPR => `/pr/${ixPR}/sil/unlink`,
    geotags: ixPR => `/pr/${ixPR}/geotag`,
    geotagsByEmp: ixPR => `/pr/${ixPR}/geotag/by-emp`,
    getNewEmployees: '/trans/search/emp-by-employment-date',
    getResignedEmployees: '/trans/search/emp-by-resret-date '
  },
  transactionMetaData: ({ jid }) => `/trans/jv/${jid}`,
  updateWFStatus: ({ jid }) => `/trans/jv/${jid}/wf/update`,
  announcements: '/trans/globals/announcements',
  calendar: '/pr/calendar',
  approvedLeaves: '/trans/search/sil-approved',
  leavesForApproval: '/trans/search/sil-for-approval',
  linkedLeaves: jid => `/pr/${jid}/sil`,
  allUserLimits: ixSub => `/trans/sub-limits/${ixSub}/all-users`,
  limits: ixSubType => `/trans/sub-limits/${ixSubType}`,
  addLog: jid => `/trans/jv/${jid}/logs/add`,
  globals: {
    sss: '/trans/globals/pr/sss',
    tax: '/trans/globals/pr/tax',
    rates: '/trans/globals/pr/rates'
  },
  searchAccounts: '/trans/search/acc',
  geotagImage: (ixImage, ixSub, filename) =>
    `/images/${ixImage}/geotag/${ixSub}/${filename}`
};

const lib = {
  sub: '/lib/sub',
  subByKVSDateRange: '/trans/search/sub-by-kvs/date-range',
  subDetails: ixSub => '/lib/sub/' + ixSub,
  prodSubCat: ixProdCat => '/lib/prod/cat/sub?ixProdCat=' + ixProdCat,
  acc: '/lib/acc',
  multiSub: '/lib/sub/multiple',
  sil: '/api/lib/sil'
};

const reports = {
  dashboard: {
    agrSummary: '/reports/agr-summary',
    agrDetailed: '/reports/agr-detail',
    agrDetailByJV: '/reports/agr-detail-jv',
    print: (ixDashboard, ixLayout) =>
      `/dashboard/${ixDashboard}/print/${ixLayout}`
  },
  daloyWF: {
    getWFTransactions: ({ ixJCd, ixStatus }) =>
      `/trans/daloy/board/${ixJCd}/${ixStatus}`
  },
  pr: {
    annualReportsMeta: '/pr/reports/annual/meta',
    annualReport: '/pr/reports/annual',
    trtnMonthMeta: '/pr/reports/13mo/meta',
    trtnMonthData: '/pr/reports/13mo'
  },
  tax: {
    form2316: '/reports/tax/print/form/2316',
    updateReportingPeriod: '/reports/tax/purchases/update',
    taxPrintLogs: '/reports/tax/print/form/2307/logs'
  },
  agr: {
    agrSummary: '/reports/agr-summary',
    agrDetail: '/reports/agr-detail',
    agrDetailByJV: '/reports/agr-detail'
  }
};

const setup = {
  fsLayouts: `/setup/fslayout`,
  fsLayout: ({ ixFSLayout }) => `/setup/fslayout/${ixFSLayout}`,
  fsLayoutMeta: `/setup/fslayout/meta`,
  subType: {
    meta: ixSubType => `/setup/sub/meta/${ixSubType}`,
    list: '/lib/sub/types'
  },
  sub: {
    subDetails: ixSub => `/setup/sub/${ixSub}`,
    list: '/lib/sub',
    images: ixSub => '/images/sub/' + ixSub
  },
  portal: {
    welcome: ixPortal => `/portal-admin/${ixPortal}/welcome`,
    linkedUsers: ixPortal => `/portal-admin/${ixPortal}/link-user`,
    activateLink: ixPortal => `/portal-admin/${ixPortal}/link-user-activate`,
    activateBizUserLink: ixPortal =>
      `/portal-admin/${ixPortal}/link-biz-user-activate`,
    linkedRegUsers: ixPortal => `/portal-admin/${ixPortal}/link-biz-user`,
    stats: ixPortal => `/portal-admin/${ixPortal}/link-stats`,
    msgTest: ixPortal => `/setup/portal/${ixPortal}/test-sms/link`,
    userDetails: ixPortal => `/portal-admin/${ixPortal}/user-detail`
  },
  dashboard: {
    printLayout: ixDashboard => `/setup/dashboard/${ixDashboard}/print-layouts`
  },
  customReport: {
    list: '/setup/reports/custom',
    settings: ixRep => '/setup/reports/custom/' + ixRep,
    privileges: ixRep => `/setup/reports/custom/${ixRep}/priv`,
    userPrivileges: (ixRep, ixUser) =>
      `/setup/reports/custom/${ixRep}/priv/${ixUser}`,
    savePrivileges: ixRep => `/setup/reports/custom/${ixRep}/priv`,
    deletePrivileges: ixRep => `/setup/reports/custom/${ixRep}/priv`,
    meta: ixRep => `/setup/reports/custom/${ixRep}/priv/meta`
  }
};

const others = {
  subDetails: ixSub => `/setup/sub/${ixSub}`
};

const jsked = {
  // Sked Setup Group

  jskedGrp: '/jsked/grp',
  setupJSkedGroups: '/jsked/setup/grp',
  setupJSkedItem: ({ ixSkedGrp }) => `/jsked/setup/grp/${ixSkedGrp}`,
  setupJSkedItemLogs: ({ ixSkedGrp }) => `/jsked/setup/grp/${ixSkedGrp}/logs`,
  setupJSkedDeletedLogs: `/jsked/setup/grp/delete-logs`,
  setupJSkedGroupTrans: '/jsked/setup/grp',

  // Group Types
  setupJSkedGroupTypes: ({ ixSkedGrp }) =>
    `/jsked/setup/grp/${ixSkedGrp}/types`,
  setupJSkedGroupTypesTrans: ({ ixSkedGrp }) =>
    `/jsked/setup/grp/${ixSkedGrp}/types`,

  setupJSkedGroupTypeItem: data =>
    `/jsked/setup/grp/${data.ixSkedGrp}/types/${data.ixSkedType}`,

  setupJSkedGroupTypeItemTrans: data =>
    `/jsked/setup/grp/${data.ixSkedGrp}/types/${data.ixSkedType}/trans`,
  setupJSkedGroupTypeItemTransLogs: data =>
    `/jsked/setup/grp/${data.ixSkedType}/types/${data.ixSkedType}/trans/logs`,

  // Schedule Transactions
  getJSkedGroups: '/jsked/grp',
  getJSkedGroupTypes: ({ ixSkedGrp }) => `/jsked/grp/${ixSkedGrp}/types`,
  getJSkedGroupTypeItem: ({ ixSkedGrp, ixSkedType }) =>
    `/jsked/grp/${ixSkedGrp}/types/${ixSkedType}`,
  getJSkedGroupTypeItemTrans: ({ ixSkedGrp, ixSkedType }) =>
    `/jsked/grp/${ixSkedGrp}/types/${ixSkedType}/trans`,
  addSkedItem: '/jsked',
  getSkedItem: ({ ixSked }) => `/jsked/${ixSked}`,
  generateSchedule: ({ ixSked }) => `/jsked/${ixSked}/generate-schedule`,
  getTransactionSchedules: ({ ixSked }) => `/jsked/${ixSked}/view-schedule`,
  generateReport: ({ ixSkedGrp }) => `/jsked/grp/${ixSkedGrp}/generate-report`
};

const portal = {
  meta: '/portal/meta',
  adminPortalItems: '/portal-admin/get-items',
  login: '/portal/pr/emp/login',
  getTransItems: ixJCd => `/portal/trans/${ixJCd}/get-items`,
  getJCdMeta: ixJCd => `/portal/trans/${ixJCd}/meta`,
  transDetails: (ixJCd, jid) => `/portal/trans/${ixJCd}/item/${jid}`,
  transAttachments: (ixJCd, jid) =>
    `/portal/trans/${ixJCd}/item/${jid}/attachments`,
  saveTransaction: ixJCd => `/portal/trans/${ixJCd}/save`,
  uploadAttachments: (ixJCd, jid) =>
    `/portal/trans/${ixJCd}/item/${jid}/attachments/upload`,
  uploadGeotag: '/portal/hris/geotag/images',
  geotagImage: filename => '/portal/hris/geotag/images/' + filename,
  geotagLog: '/portal/hris/geotag',
  prList: ixJCd => '/portal/hris/pr/get-items-by-type/' + ixJCd,
  prDetails: jid => '/portal/hris/pr/get-item/' + jid,
  bioDetails: jid => '/portal/hris/pr/get-bio/' + jid,
  appliedLeaves: jid => '/portal/hris/pr/get-sil/' + jid,
  calendar: '/portal/hris/pr/calendar',
  silBalance: '/portal/hris/sil-bal',
  silLedger: '/portal/hris/sil-ledger',
  comments: ({ ixJCd, jid }) => `/portal/trans/${ixJCd}/item/${jid}/comments`,
  resetPassword: '/portal/user/change-pw',
  transUpdate: (JCd, jid) => `/portal/trans/${JCd}/update/${jid}`
};

const budget = {
  getMeta: '/reports/budget/meta',
  getSummary: '/reports/budget/summary',
  getRAPAL: '/reports/budget/rapal',
  getRAOD: '/reports/budget/raod',
  getLedger: '/reports/budget/ledger',
  getLedgerRecap: '/reports/budget/ledger-recap'
};

const jnt = {
  addOrder: '/ext/jnt/create',
  trackOrder: '/ext/jnt/track'
};

const endpoints = {
  registry,
  trans,
  reports,
  setup,
  others,
  jsked,
  portal,
  budget,
  lib
};

export default endpoints;
export {
  registry,
  trans,
  reports,
  setup,
  others,
  jsked,
  portal,
  budget,
  lib,
  jnt
};
