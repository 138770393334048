import { v4 as uuid } from 'uuid';
import { round, isNumber } from 'lodash';

class Loan {
  id;
  ixTrans;
  ixEmp;
  sEmp;
  ixAcc;
  sAcc;
  Amt;
  Remarks;
  ixReqBalSrc;
  ixBrch;
  ixSub1;
  ixSub2;
  ixSub3;
  ixSub4;
  ixSub5;
  sSubAcc;
  sSubAcc2;
  sSubAcc3;
  sSubAcc4;
  sSubAcc5;
  amtBal;
  isDeleted;

  constructor({
    id = uuid(),
    ixTrans = null,
    ixEmp = 0,
    sEmp = '',
    ixAcc = 0,
    sAcc = '',
    Amt = 0,
    Remarks = '',
    ixReqBalSrc,
    ixSub1 = 0,
    ixSub2 = 0,
    ixSub3 = 0,
    ixSub4 = 0,
    ixSub5 = 0,
    sSubAcc = '',
    sSubAcc2 = '',
    sSubAcc3 = '',
    sSubAcc4 = '',
    sSubAcc5 = '',
    amtBal = 0,
    isDeleted = false,
    ixBrch = 0
  }) {
    this.id = id;
    this.ixEmp = ixEmp;
    this.sEmp = sEmp;
    this.ixAcc = ixAcc;
    this.sAcc = sAcc;
    this.Amt = round(Amt, 4);
    this.Remarks = Remarks;
    this.ixReqBalSrc = ixReqBalSrc;
    this.ixSub1 = ixSub1;
    this.ixSub2 = ixSub2;
    this.ixSub3 = ixSub3;
    this.ixSub4 = ixSub4;
    this.ixSub5 = ixSub5;
    this.sSubAcc = sSubAcc;
    this.sSubAcc2 = sSubAcc2;
    this.sSubAcc3 = sSubAcc3;
    this.sSubAcc4 = sSubAcc4;
    this.sSubAcc5 = sSubAcc5;
    this.amtBal = amtBal;
    this.ixTrans = ixTrans;
    this.isDeleted = isDeleted;
    this.ixBrch = ixBrch;
  }

  update(data = {}) {
    Object.keys(data).forEach(key => {
      this[key] = isNumber(data[key]) ? round(data[key], 4) : data[key];
    });
  }
}

export default Loan;
