import { lazy } from 'react';
import DashboardLayout from 'src/layouts/DashboardLayout';
import Dashboard from 'src/views/reports/DashboardView';
import ResetPortalPassword from 'src/views/auth/ResetPortalPassword';
import GuestPortalPage from 'src/views/GuestPortal';
import LandingPageLayout from 'src/layouts/LandingPageLayout';

// Reports
const GLReport = lazy(() => import('src/views/reports/GL'));
const CashflowLedger = lazy(() => import('src/views/reports/CashflowLedger'));
const GJReport = lazy(() => import('src/views/reports/GJ'));
const SLReport = lazy(() => import('src/views/reports/SL'));
const SL2 = lazy(() => import('src/views/reports/SL2'));
const SCReport = lazy(() => import('src/views/reports/SC'));
const StockImage = lazy(() => import('src/views/reports/inventory/StockImage'));
const CustomReport = lazy(() => import('src/views/reports/custom'));
const WFAdmin = lazy(() => import('src/views/reports/WFAdmin'));
const WFStats = lazy(() => import('src/views/reports/WFStats'));
const DaloyBoard = lazy(() => import('src/views/reports/daloyBoard'));
const OrderSummary = lazy(() => import('src/views/reports/OrderSummary'));
const SLSubBalances = lazy(() => import('src/views/reports/SLSubBalances'));
const AdvSLSubBalances = lazy(() =>
  import('src/views/reports/AdvSLSubBalances')
);
const SalesSummary = lazy(() =>
  import('src/views/reports/inventory/SalesSummary')
);
const PurchaseSummary = lazy(() =>
  import('src/views/reports/inventory/PurchaseSummary')
);
const WHTransferSummary = lazy(() =>
  import('src/views/reports/inventory/WHTransferSummary')
);
const BrTransferSummary = lazy(() =>
  import('src/views/reports/inventory/BrTransferSummary')
);
const SpecialJournalReport = lazy(() =>
  import('src/views/reports/SpecialJournalReport')
);
const FS = lazy(() => import('src/views/reports/FinancialStatementReport'));
const SummaryOfSales = lazy(() => import('src/views/reports/SOS'));
const SummaryOfPurchases = lazy(() => import('src/views/reports/SOP'));
const CashPositionReport = lazy(() =>
  import('src/views/reports/CashPositionReport')
);
const MatureChecksReports = lazy(() =>
  import('src/views/reports/MaturedChecksReport')
);
const CheckRegister = lazy(() => import('src/views/reports/CheckRegister'));
const Aging = lazy(() => import('src/views/reports/AgingReport'));
const Imbal = lazy(() => import('src/views/reports/ImbalanceReport'));
const JePts = lazy(() => import('src/views/reports/JePts'));
const JePtsSummary = lazy(() => import('src/views/reports/JePtsSummary'));
const JePtsReportSummaryPerCustomer = lazy(() =>
  import('src/views/reports/JePtsSummaryPerCustomer')
);
const AnnualReports = lazy(() =>
  import('src/views/reports/Payroll/AnnualReports')
);
const EmployeeDashboard = lazy(() =>
  import('src/views/reports/Payroll/EmployeeDashboard')
);

const BudgetSummary = lazy(() => import('src/views/reports/Budget/Summary'));
const BudgetRAPAL = lazy(() => import('src/views/reports/Budget/RAPAL'));
const BudgetRAOD = lazy(() => import('src/views/reports/Budget/RAOD'));
const BudgetLedger = lazy(() => import('src/views/reports/Budget/Ledger'));
const BudgetSAAO = lazy(() => import('src/views/reports/Budget/SAAO'));

// Setup
const SubTypes = lazy(() => import('src/views/setup/subTypes'));
const Validation = lazy(() => import('src/views/setup/Validation'));
const BizSettings = lazy(() => import('src/views/setup/business'));
const AccountSettings = lazy(() => import('src/views/setup/account'));
const SetupSub = lazy(() => import('src/views/setup/sub'));
const TransactionSettings = lazy(() => import('src/views/setup/transactions'));
const ProductSettings = lazy(() => import('src/views/setup/products'));
const ProductCategorySettings = lazy(() =>
  import('src/views/setup/productCategories')
);
const ProductSubCategorySettings = lazy(() =>
  import('src/views/setup/productSubCategories')
);
const CustomReportSettings = lazy(() => import('src/views/setup/customReport'));
const SetupPortal = lazy(() => import('src/views/setup/portal'));
const PortalAdmin = lazy(() => import('src/views/setup/PortalAdmin'));
const PortalUserView = lazy(() =>
  import('src/views/setup/PortalAdmin/PortalUserView')
);
const SetupCustomDashboard = lazy(() => import('src/views/setup/dashboard'));
const DashboardDocs = lazy(() =>
  import('src/views/setup/dashboard').then(module => ({
    default: module.DashboardDocumentation
  }))
);
const CalendarSetup = lazy(() => import('src/views/setup/calendar'));
const EmployeeShifts = lazy(() => import('src/views/setup/employeeShifts'));
const FSLayoutSetup = lazy(() => import('src/views/setup/fsLayout'));
const SpecialJournalSetup = lazy(() =>
  import('src/views/setup/SpecialJournal')
);
const UserListView = lazy(() => import('src/views/setup/users/UsersListView'));
const UserDetail = lazy(() => import('src/views/setup/users/detail'));
const Privileges = lazy(() => import('src/views/setup/privileges'));
const TargetsSetup = lazy(() => import('src/views/setup/targets'));
const DashboardAdvance = lazy(() =>
  import('src/views/reports/DashboardAdvance')
);
const PostingSummary = lazy(() => import('src/views/reports/PostingSummary'));
const DepreciationReport = lazy(() =>
  import('src/views/reports/DepreciationReport')
);
const TrtnMonthPay = lazy(() =>
  import('src/views/reports/Payroll/TrtnMonthPay')
);

// transactions
const JCdListView = lazy(() => import('src/views/trans/JCdListView'));
const TransactionPage = lazy(() => import('src/views/trans/JCdTrans'));
const TransView = lazy(() => import('src/views/trans/TransView'));
const Payroll = lazy(() => import('src/views/trans/Payroll'));
const JSked = lazy(() => import('src/views/trans/JSked'));
const CheckSked = lazy(() => import('src/views/trans/CheckSked'));
const EndingInventory = lazy(() =>
  import('src/views/reports/inventory/EndingInventory')
);
const VerifyEmail = lazy(() => import('src/views/auth/VerifyEmail'));
const ActivatePhone = lazy(() => import('src/views/auth/ActivatePhone'));

// user account
const AccountView = lazy(() => import('src/views/account/AccountView'));

const appRoutes = [
  {
    path: 'app',
    element: DashboardLayout,
    children: [
      {
        path: '*',
        element: Dashboard
      },
      {
        path: 'dashboard',
        element: Dashboard
      },
      {
        path: 'dashboard/:ixDashboard',
        element: DashboardAdvance
      },
      {
        path: 'activate-phone-number',
        element: ActivatePhone
      },
      {
        path: 'verify-email/:cd/:email',
        element: VerifyEmail
      },
      {
        path: 'account',
        element: AccountView
      },
      {
        path: 'reports',
        children: [
          {
            path: 'gl',
            element: GLReport
          },
          {
            path:
              'gl/id/:ixAcc/:sAcc/start/:dt1/end/:dt2/sDate/:sDate/action/:action',
            element: GLReport
          },
          {
            path: 'gj',
            element: GJReport
          },
          {
            path: 'cfl',
            element: CashflowLedger
          },
          {
            path:
              'cfl/id/:ixAcc/:sAcc/start/:dt1/end/:dt2/sDate/:sDate/ixBrch/:ixBrch/action/:action',
            element: CashflowLedger
          },
          {
            path:
              'gl/id/:ixAcc/:sAcc/start/:dt1/end/:dt2/sDate/:sDate/ixBrch/:ixBrch/action/:action',
            element: GLReport
          },
          {
            path: 'sl',
            element: SLReport
          },
          {
            path: 'sl2',
            element: SL2
          },
          {
            path:
              'sl/id/:ixAcc/:sAcc/sub-id/:ixSub/:sSub/sub-type-id/:ixSubTypeId/sub-type/:sSubType/start/:dt1/end/:dt2/sDate/:sDate/action/:action',
            element: SLReport
          },
          {
            path:
              'sl/id/:ixAcc/:sAcc/sub-id/:ixSub/:sSub/sub-type-id/:ixSubTypeId/sub-type/:sSubType/start/:dt1/end/:dt2/sDate/:sDate/brch/:ixBrch/action/:action',

            element: SLReport
          },

          {
            path: 'sl-balances-summary',
            element: SLSubBalances
          },
          {
            path: 'adv-sl-balances-summary',
            element: AdvSLSubBalances
          },
          {
            path: 'sc',
            element: SCReport
          },
          {
            path: 'stock-image',
            element: StockImage
          },
          {
            path: 'custom',
            element: CustomReport
          },
          {
            path: 'wf-admin',
            element: WFAdmin
          },
          {
            path: 'wf-stats',
            element: WFStats
          },
          {
            path: 'daloy-board',
            element: DaloyBoard
          },
          {
            path: 'order-summary/:type',
            element: OrderSummary
          },
          {
            path: 'sales-summary',
            element: SalesSummary
          },
          {
            path: 'purchase-summary',
            element: PurchaseSummary
          },
          {
            path: 'wh-transfer-summary',
            element: WHTransferSummary
          },
          {
            path: 'br-transfer-summary',
            element: BrTransferSummary
          },
          {
            path: 'ending-inventory',
            element: EndingInventory
          },
          {
            path: 'special-journals',
            element: SpecialJournalReport
          },
          {
            path: 'fs',
            element: FS
          },
          {
            path: 'summary-of-sales',
            element: SummaryOfSales
          },
          {
            path: 'posting-summary',
            element: PostingSummary
          },
          {
            path: 'summary-of-purchases',
            element: SummaryOfPurchases
          },
          {
            path: 'cash-position-report',
            element: CashPositionReport
          },
          {
            path: 'check-register',
            element: CheckRegister
          },
          {
            path: 'mature-checks-report',
            element: MatureChecksReports
          },
          {
            path: 'payroll',
            children: [
              {
                path: '13th-month-pay',
                element: TrtnMonthPay
              },
              {
                path: 'annual-reports',
                element: AnnualReports
              },
              {
                path: 'employee-dashboard',
                element: EmployeeDashboard
              }
            ]
          },
          {
            path: 'aging',
            element: Aging
          },
          {
            path: 'imbalanced-report',
            element: Imbal
          },
          {
            path: 'jepts',
            element: JePts
          },
          {
            path: 'jepts-summary',
            element: JePtsSummary
          },
          {
            path: 'jepts-summary-per-customer',
            element: JePtsReportSummaryPerCustomer
          },
          {
            path: 'jsked/:groupId',
            element: DepreciationReport
          },
          {
            path: 'budget',
            children: [
              {
                path: 'summary',
                element: BudgetSummary
              },
              {
                path: 'rapal',
                element: BudgetRAPAL
              },
              {
                path: 'raod',
                element: BudgetRAOD
              },
              {
                path: 'ledger',
                element: BudgetLedger
              },
              {
                path: 'saao',
                element: BudgetSAAO
              },
              {
                path:
                  'ledger/account/:ixAcc/:sAcc/ixExpCls/:ixExpCls/rc/:ixSub/:sSub/ixBrch/:ixBrch/start/:dt1/end/:dt2/sDate/:sDate',
                element: BudgetLedger
              }
            ]
          }
        ]
      },
      {
        path: 'setup',
        children: [
          {
            path: 'business/:activeMenu',
            element: BizSettings
          },
          {
            path: 'business',
            element: BizSettings
          },
          {
            path: 'accounts',
            element: AccountSettings
          },
          {
            path: ':type/types',
            element: SubTypes
          },
          {
            path: 'sub-accounts',
            element: SetupSub
          },
          {
            path: 'sub-accounts/:page',
            element: SetupSub
          },
          {
            path: 'transactions',
            element: TransactionSettings
          },
          {
            path: 'products',
            element: ProductSettings
          },
          {
            path: 'product-categories',
            element: ProductCategorySettings
          },
          {
            path: 'product-sub-categories',
            element: ProductSubCategorySettings
          },
          {
            path: 'custom-reports',
            element: CustomReportSettings
          },
          {
            path: 'dashboards',
            element: SetupCustomDashboard
          },
          {
            path: 'dashboards/docs',
            element: DashboardDocs
          },
          {
            path: 'fs-layout',
            element: FSLayoutSetup
          },
          {
            path: 'special-journal',
            element: SpecialJournalSetup
          },
          {
            path: 'users',
            element: UserListView
          },
          {
            path: 'users/:user_uuid',
            element: UserDetail
          },
          {
            path: 'privileges',
            element: Privileges
          },
          {
            path: 'targets',
            element: TargetsSetup
          },
          {
            path: 'settings-validation',
            element: Validation
          },
          {
            path: 'settings-validation/:page',
            element: Validation
          },
          {
            path: 'portal',
            element: SetupPortal
          },
          {
            path: 'calendar',
            element: CalendarSetup
          },
          {
            path: 'portal-admin',
            element: PortalAdmin
          },
          {
            path: 'portal-admin/:ixPortal/user/:ixSubUser',
            element: PortalUserView
          },
          {
            path: 'employee-shifts',
            element: EmployeeShifts
          }
        ]
      },
      {
        path: 'trans',
        children: [
          {
            path: ':jid',
            element: TransView
          },
          {
            path: 'jcd/:jcd_uuid',
            element: JCdListView
          },
          { path: 'jcd/:jcd_uuid/new', element: TransactionPage },
          {
            path: 'jcd/:jcd_uuid/:type/:jid',
            element: TransactionPage
          },
          {
            path: 'jsked',
            element: JSked
          },
          {
            path: 'jsked/grp/:grp_id',
            element: JSked
          },
          {
            path: 'check-sked/:jcd_uuid',
            element: CheckSked
          }
        ]
      },
      {
        path: 'pr',
        children: [
          { path: 'jcd/:jcd_uuid/:type', element: Payroll },
          {
            path: 'jcd/:jcd_uuid/:type/:jid',
            element: Payroll
          }
        ]
      }
    ]
  },
  {
    path: 'portal/:cdBiz/:cdPortal/reset-password',
    element: ResetPortalPassword
  },
  {
    path: 'portal/:cdBiz/:cdPortal/reset-password/:username',
    element: ResetPortalPassword
  },
  {
    path: 'guest-portal',
    element: LandingPageLayout,
    children: [
      {
        path: ":cdBiz/:cdPortal/:ixJCd",
        element: GuestPortalPage
      }
    ]
  }

];

export default appRoutes;
