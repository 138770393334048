import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWarehouse } from 'src/redux/slices/storedValues';
import useNotif from './useNotif';
import useRequest from './useRequest';
import useToggle from './useToggleV2';

const useWarehouse = ({
  include = [],
  exclude = [],
  ixJCd = 0,
  selectedWarehouse = 0
}) => {
  const dispatch = useDispatch();
  const { warehouse: warehouses } = useSelector(
    ({ storedValues }) => storedValues
  );
  const [loading, loadingOn, loadingOff] = useToggle();
  const request = useRequest(loadingOn, loadingOff);
  const { error } = useNotif();

  const getWarehouse = async cancelToken => {
    let url = ixJCd !== 0 ? `/trans/get/wh/${ixJCd}` : '/trans/get/wh';
    const { success, data, isCancelled } = await request.get(
      url,
      {},
      cancelToken
    );
    if (success) dispatch(setWarehouse(data));
    else if (!success && isCancelled) return;
    else if (!success) error('Failed to get warehouses.');
  };

  const getWH = () => {
    let whs = [];

    if (include.length > 0)
      whs = warehouses.filter(data => include.includes(data.ixWH));
    else if (exclude.length > 0)
      whs = warehouses.filter(data => !exclude.includes(data.ixWH));
    else whs = warehouses;

    if (
      selectedWarehouse !== 0 &&
      !whs.find(item => item?.ixWH === selectedWarehouse)
    ) {
      if (warehouses.find(item => item?.ixWH === selectedWarehouse))
        whs.push(warehouses.find(item => item?.ixWH === selectedWarehouse));
    }

    return whs;
  };

  useEffect(() => {
    let cancelToken = () => null;

    getWarehouse(function(c) {
      cancelToken = c;
    });

    return () => {
      cancelToken();
    };
  }, [ixJCd]);

  return {
    warehouses: getWH(),
    loading
  };
};

export default useWarehouse;
