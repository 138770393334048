import React, { useState, lazy, Suspense } from "react";
import {
  Container,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import hero from "../images/bg_1.jpg";

const importAll = (r) => r.keys().map(r);

const bibleStudyImages = importAll(require.context("../Gallery/images/bibleStudy", false, /\.(jpg|jpeg|png)$/));
const birthdayImages = importAll(require.context("../Gallery/images/birthday", false, /\.(jpg|jpeg|png)$/));
const clientImages = importAll(require.context("../Gallery/images/client", false, /\.(jpg|jpeg|png)$/));
const deployMomentImages = importAll(require.context("../Gallery/images/deploMoment", false, /\.(jpg|jpeg|png)$/));
const foodImages = importAll(require.context("../Gallery/images/food", false, /\.(jpg|jpeg|png)$/));
const funMomentImages = importAll(require.context("../Gallery/images/funMoment", false, /\.(jpg|jpeg|png)$/));
const orientationImages = importAll(require.context("../Gallery/images/orientation", false, /\.(jpg|jpeg|png)$/));
const reportingImages = importAll(require.context("../Gallery/images/reporting", false, /\.(jpg|jpeg|png)$/));

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  heroSection: {
    position: "relative",
    textAlign: "center",
    color: "white",
    marginBottom: theme.spacing(4),
    backgroundImage: `url(${hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  heroText: {
    zIndex: 1,
    "& h1": {
      fontSize: "3rem",
      fontWeight: 700,
    },
    "& p": {
      fontSize: "1.25rem",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#2e2c3c86",
    zIndex: 0,
  },
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 350,
  },
  media: {
    height: 150,
    objectFit: "cover",
  },
  cardTitle: {
    padding: theme.spacing(2),
    flexGrow: 1,
    overflow: "hidden",
    "& h5": {
      fontWeight: "bold",
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  gridImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    cursor: "pointer",
  },
  fullImage: {
    width: "100%",
    height: "auto",
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const albums = [
  {
    title: "Bible Study",
    description: "Together in His presence. Building a foundation of faith, one Biblestudy at a time.",
    images: bibleStudyImages,
  },
  {
    title: "Birthday Celebrations",
    description:
      "Celebrating the best CEO ever and the powerhouse of business development as they lead the Radztech Team to success and new heights.",
    images: birthdayImages,
  },
  {
    title: "Client Moments",
    description:
      "Interns dive into real-world experience by joining dynamic client meetings, both online and face-to-face, gaining invaluable exposure and insights.",
    images: clientImages,
  },
  {
    title: "Deploy Moments",
    description:
      "Interns are assigned to various company clients, working collaboratively to address their needs and achieve common goals. They interact directly with clients, provide system guidance, and supply essential materials for support.",
    images: deployMomentImages,
  },
  {
    title: "Food Hangouts",
    description: "Gather, eat, laugh and repeat with the Radztech Team. Nothing brings people together like good “Balut” and siomai.",
    images: foodImages,
  },
  {
    title: "Fun Moments",
    description:
      "In a single camera’s click ripples countless photos as the interns were unstoppable to show their smiles and take poses.",
    images: funMomentImages,
  },
  {
    title: "Orientation",
    description:
      "Kick off their professional journey by exploring the company culture, meeting fellow interns, and understanding their role. The first day is designed to familiarize with the company teams, outline their duties, and set the tone for an enriching internship experience.",
    images: orientationImages,
  },
  {
    title: "Reporting",
    description:
      "Interns unite their efforts, blending individual insights into collective success. From meticulous reporting to seamless teamwork, they elevate each task with unity and purpose.",
    images: reportingImages,
  },
];

const Gallery = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [fullViewOpen, setFullViewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (album) => {
    setSelectedAlbum(album);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAlbum(null);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setFullViewOpen(true);
  };

  const handleFullViewClose = () => {
    setFullViewOpen(false);
    setSelectedImage(null);
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <div className={classes.heroSection}>
        <div className={classes.overlay}></div>
        <div className={classes.heroText}>
          <Typography variant="h1">Interns Gallery</Typography>
          <Typography variant="body1">A Journey Frozen in Time</Typography>
        </div>
      </div>
      <Grid container spacing={2}>
        {albums.map((album, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <CardActionArea onClick={() => handleOpen(album)}>
              <Card className={classes.card}>
                <CardMedia className={classes.media} image={album.images[0]} title={album.title} />
                <div className={classes.cardTitle}>
                  <Typography variant="h5">{album.title}</Typography>
                  <Typography variant="body2">{album.description}</Typography>
                </div>
              </Card>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{selectedAlbum?.title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2}>
            {selectedAlbum?.images.map((image, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx}>
                <img
                  src={image}
                  alt={`album-image-${idx}`}
                  className={classes.gridImage}
                  onClick={() => handleImageClick(image)}
                  loading="lazy"
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={fullViewOpen} onClose={handleFullViewClose} maxWidth="md" fullWidth>
        <IconButton className={classes.closeIcon} onClick={handleFullViewClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <img src={selectedImage} alt="Full view" className={classes.fullImage} loading="lazy" />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Gallery;
