import React, { useEffect, useRef } from 'react';
import Page from 'src/components/Page';
import GuestPortal from './GuestPortal';
import { useParams } from 'react-router';

const GuestPortalPage = () => {
    const guestPortal = useRef();
    const { ixJCd } = useParams();


    useEffect(() => {
        guestPortal.current.scrollIntoView();
    }, []);

    return (
        <Page title="UlapBiz">
            <div ref={guestPortal}>
                <GuestPortal />
            </div>
        </Page>
    );
};

export default GuestPortalPage;
