import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { isNumber, round } from 'lodash';

class Deduction {
  id;
  ixEmp;
  sEmp;
  ixDed;
  sDed;
  EeAmt;
  ErAmt;
  Qty;
  Rate;
  Particulars;
  tsDate;

  constructor({
    id = uuid(),
    ixEmp = 0,
    sEmp = '',
    ixDed = 0,
    sDed = '',
    EeAmt = 0,
    ErAmt = 0,
    Qty = 0,
    Rate = 0,
    Particulars = '',
    tsDate = moment().format('YYYY-MM-DDTHH:mm:ss')
  }) {
    this.id = id;
    this.ixEmp = ixEmp;
    this.sEmp = sEmp;
    this.ixDed = ixDed;
    this.sDed = sDed;
    this.EeAmt = round(EeAmt, 4);
    this.ErAmt = round(ErAmt, 4);
    this.Qty = Qty;
    this.Rate = Rate;
    this.Particulars = Particulars;
    this.tsDate = tsDate;
  }

  update(data = {}) {
    Object.keys(data).forEach(key => {
      if (key in this)
        this[key] = isNumber(data[key]) ? round(data[key], 4) : data[key];
    });
  }
}

export default Deduction;
