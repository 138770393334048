import { createSlice } from '@reduxjs/toolkit';
import APIRequest, {
  cancelAPIRequest,
  APIRequestV2
} from 'src/helpers/APIRequest';
import { FS_TYPES } from 'src/constants';

const INITIAL_STATE = {
  reportFilters: {
    ixFSMode: 0,
    ixEType: 0,
    ixFSLayout: 0,
    dateRangeAsOf: {
      dt1: null,
      dt2: null,
      textValue: ''
    },
    dateRange: {
      dt1: null,
      dt2: null,
      textValue: ''
    },
    bAsOf: false,
    compare_type: 'month',
    brch: {
      required: false,
      ixBrch: 0,
      label: ''
    },
    byComp: false,
    ixBrchParent: 0,
    merge_acc: false,
    selectedReport: 0,
    showHidden: false,
    showIgnoredItems: false,
    showAccType: false
  },
  ixFSMode: 0,
  ixEType: 0,
  ixFSLayout: 0,
  dateRange: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  dateRangeAsOf: {
    dt1: null,
    dt2: null,
    textValue: ''
  },
  data: null,
  bAsOf: false,
  error: false,
  errorMessages: [],
  compare_type: 'month',
  brch: {
    required: false,
    ixBrch: 0,
    label: ''
  },
  byComp: false,
  ixBrchParent: 0,
  merge_acc: false,
  selectedReport: 0,
  loading: false,
  showHidden: false,
  showIgnoredItems: false,
  showAccType: false,
  meta: {}
};

const slice = createSlice({
  name: 'fs',
  initialState: INITIAL_STATE,
  reducers: {
    loadMeta(state, action) {
      state.meta = action.payload;
    },
    selectReport(state, action) {
      state.reportFilters.selectedReport = action.payload;
      state.reportFilters.ixEType = 0;
      state.reportFilters.ixFSLayout = 0;
      state.reportFilters.bAsOf = +action.payload === 300;

      // comparative by branch (is and cf)
      if (
        [
          FS_TYPES.INCOME_STATEMENT_BY_BRCH,
          FS_TYPES.CASH_FLOW_BY_BRCH
        ].includes(+action.payload)
      )
        state.reportFilters.brch.ixBrch = 0;
    },
    selectReportType(state, action) {
      state.reportFilters.ixEType = action.payload;
    },
    selectReportMode(state, action) {
      state.reportFilters.ixFSMode = action.payload;
    },
    changeReportDate(state, action) {
      state.reportFilters.dateRange.dt1 = action.payload.dt1;
      state.reportFilters.dateRange.dt2 = action.payload.dt2;
      state.reportFilters.dateRange.textValue = action.payload.textValue;
    },
    onFetchReportData(state, action) {
      state.loading = true;
      state.error = false;
      state.errorMessages = '';
    },
    loadReportData(state, action) {
      state.ixFSMode = state.reportFilters.ixFSMode;
      state.ixEType = state.reportFilters.ixEType;
      state.ixFSLayout = state.reportFilters.ixFSLayout;
      state.dateRange.dt1 = state.reportFilters.dateRange.dt1;
      state.dateRange.dt2 = state.reportFilters.dateRange.dt2;
      state.dateRange.textValue = state.reportFilters.dateRange.textValue;
      state.bAsOf = state.reportFilters.bAsOf;
      state.compare_type = state.reportFilters.compare_type;
      state.brch = state.reportFilters.brch;
      state.by_brch = state.reportFilters.by_brch;
      state.selectedReport = state.reportFilters.selectedReport;
      state.showHidden = state.reportFilters.showHidden;
      state.showIgnoredItems = state.reportFilters.showIgnoredItems;
      state.merge_acc = state.reportFilters.merge_acc;
      state.ixBrchParent = state.reportFilters.ixBrchParent;
      state.byComp = state.reportFilters.byComp;
      state.loading = false;
      state.data = action.payload;
      state.error = false;
      state.errorMessages = [];
    },
    loadError(state, action) {
      state.loading = false;
      state.error = true;
      state.errorMessages = action.payload;
    },
    clearReportData(state, action) {
      state.data = null;
      state.error = false;
      state.errorMessages = [];
    },
    setFSBranches(state, action) {
      state.reportFilters.brch.required = action.payload.required;
      state.reportFilters.brch.label = action.payload.label_singular;
      state.brch = state.reportFilters.brch;
      state.brch.required = action.payload.required;
      state.brch.label = action.payload.label_singular;
    },

    changeFSBranch(state, action) {
      state.reportFilters.brch.ixBrch = action.payload.ixBrch;
      state.reportFilters.ixBrchParent = action.payload.ixParent;
    },
    changeByComp(state, action) {
      state.reportFilters.byComp = action.payload;
      state.reportFilters.brch.ixBrch = 0;
      state.reportFilters.ixBrchParent = 0;
    },
    changeFSLayout(state, action) {
      state.reportFilters.ixFSLayout = action.payload;
    },
    changeCompareType(state, action) {
      state.reportFilters.compare_type = action.payload;
    },
    showHidden(state, action) {
      state.reportFilters.showHidden = action.payload;
    },
    toggleShowAccType(state, action) {
      state.showAccType = action.payload;
    },
    toggleMergeAcc(state, action) {
      state.reportFilters.merge_acc = action.payload;
    },
    changeAsOf(state, action) {
      state.reportFilters.bAsOf = action.payload;
    },
    changeShowIgnoredItems(state, action) {
      state.reportFilters.showIgnoredItems = action.payload;
    },
    cancelReportRequest(state, action) {
      state.loading = false;
    },
    clear(state, action) {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

export const getReportProps = (code, data) => {
  const props = {
    ixEType: data.ixEType,
    ixFSLayout: data.ixFSLayout,
    dt1: data.dateRange.dt1,
    dt2: data.dateRange.dt2,
    textValue: data.dateRange.textValue,
    sDate: data.dateRange.textValue,
    showHidden: data?.showHidden || false,
    showParams: false,
    ignore_items_not_in_layout: data?.showIgnoredItems ?? false
  };

  if (data?.brch?.required) props.ixBrch = data.brch.ixBrch;

  // for income and balance sheet comparative report
  if (
    [
      FS_TYPES.COMPARATIVE_INCOME_STATEMENT,
      FS_TYPES.COMPARATIVE_BALANCE_SHEET
    ].includes(+code)
  )
    props.compare_type = data.compare_type;

  // trial balance and balance sheet (as of date)

  if ([FS_TYPES.TRIAL_BAL, FS_TYPES.BALANCE_SHEET].includes(+code))
    props.bAsOf = data?.bAsOf ?? false;

  if ([FS_TYPES.CASH_FLOW, FS_TYPES.CASH_FLOW_BY_BRCH].includes(+code))
    props.merge_acc = data?.merge_acc ?? false;

  switch (+code) {
    case FS_TYPES.TRIAL_BAL:
      return { url: '/reports/fs/tb', ...props };
    case FS_TYPES.INCOME_STATEMENT:
      return { url: '/reports/fs/is', ...props };
    case FS_TYPES.COMPARATIVE_INCOME_STATEMENT:
      return {
        url: '/reports/fs/is-comparative',
        ...props
      };
    case FS_TYPES.INCOME_STATEMENT_BY_BRCH:
      return {
        url: '/reports/fs/is-comparative-brch',
        ...props
      };
    case FS_TYPES.BALANCE_SHEET:
      return { url: '/reports/fs/bs', ...props };
    case FS_TYPES.COMPARATIVE_BALANCE_SHEET:
      return {
        url: '/reports/fs/bs-comparative',
        ...props
      };
    case FS_TYPES.CASH_FLOW:
      return {
        url: '/reports/fs/cf',
        ...props
      };
    case FS_TYPES.CASH_FLOW_BY_BRCH:
      return {
        url: '/reports/fs/cf-comparative-brch',
        ...props
      };
    case FS_TYPES.CHANGES_IN_EQUITY:
      return {
        url: '/reports/fs/eq',
        ...props
      };
    default:
      return {};
  }
};

export const fetchMeta = () => async (dispatch, getState) => {
  const { base_url, userToken } = getState().auth;
  const { frontEnd } = getState().bizMeta;

  const { success, data } = await APIRequest({
    url: `${base_url}/reports/fs/meta`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    }
  });

  if (!success) return;

  // get fs description on frontend settings
  const copy = { ...data };
  Object.entries(copy.FS_TYPES).forEach(([key, value]) => {
    copy.FS_TYPES[key] = frontEnd?.fs_description?.[key] ?? value;
  });

  dispatch(slice.actions.loadMeta(copy));
};

export let cancelRequest = cancelAPIRequest;

export const fetchReportData = () => async (dispatch, getState) => {
  const { reportFilters } = getState().fs;
  const { brch, brchAsBiz } = getState().bizMeta;
  const { selectedReport, by_brch, ixBrchParent, byComp, ...rest } =
    reportFilters || {};
  const { loadError, onFetchReportData } = slice.actions;
  const { base_url, userToken } = getState().auth;
  const { url, ...props } = getReportProps(selectedReport, rest);
  let payload = props;

  if (
    brch.mode === 2 &&
    [FS_TYPES.BALANCE_SHEET, FS_TYPES.COMPARATIVE_BALANCE_SHEET].includes(
      +selectedReport
    )
  ) {
    payload = {
      ...payload,
      ixBrch: 0
    };
  }

  if (
    Boolean(brchAsBiz?.allow) &&
    byComp &&
    [FS_TYPES.INCOME_STATEMENT, FS_TYPES.BALANCE_SHEET].includes(
      +selectedReport
    )
  ) {
    payload = {
      ...payload,
      brchAsBiz: true,
      ixBrchParent
    };
  }

  dispatch(onFetchReportData());

  const { success, error, data, isCancelled } = await APIRequestV2({
    method: 'POST',
    url: `${base_url}${url}`,
    headers: {
      'Content-Type': 'application/json',
      'x-access-tokens': userToken
    },
    data: payload
  });

  if (success) {
    dispatch(slice.actions.loadReportData(data));
    return;
  }

  if (isCancelled) {
    return;
  }

  if (Boolean(error?.data?.errors)) {
    dispatch(loadError(error?.data?.errors ?? []));
    return;
  }

  dispatch(
    loadError([
      {
        msg:
          error?.data?.msg ??
          error?.data?.description ??
          error?.data ??
          'Something went wrong. Please try again.',
        ob: null
      }
    ])
  );
};

export const cancelReportRequest = () => dispatch => {
  if (cancelRequest) cancelRequest();
  dispatch(slice.actions.cancelReportRequest());
};

const fsReducer = slice.reducer;
export const {
  loadMeta,
  selectReport,
  selectReportType,
  selectReportMode,
  changeReportDate,
  loadReportData,
  toggleShowAccType,
  toggleMergeAcc,
  clearReportData,
  setFSBranches,
  setParentBranch,
  changeFSBranch,
  changeAsOf,
  changeByComp,
  changeFSLayout,
  changeCompareType,
  showHidden,
  changeShowIgnoredItems,
  restore,
  clear
} = slice.actions;
export default fsReducer;
