import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import { configureStore } from '@reduxjs/toolkit';

import registrationReducer from './reducers/registration';
import {
  advanceDashboardReducer,
  agingReducer,
  announcementReducer,
  authenticationReducer,
  bizMetaReducer,
  bizStateReducer,
  cashPositionReducer,
  customReportReducer,
  daloyReducer,
  dashboardCacheReducer,
  dashboardReducer,
  fsReducer,
  glReducer,
  globalStateReducer,
  imbalanceReducer,
  jePtsReducer,
  jePtsSummaryReducer,
  matureChecksReducer,
  orderSummaryReducer,
  postingSummaryReducer,
  preferencesReducer,
  privilegesReducer,
  purchaseSummaryReducer,
  registryReducer,
  salesSummaryReducer,
  setupReducer,
  scReducer,
  slReducer,
  slSubBalancesReducer,
  advSLSubBalancesReducer,
  sopReducer,
  storedValuesReducer,
  sosReducer,
  specialJournalsReducer,
  transactionReducer,
  wfAdminReducer,
  wfAssignedReducer,
  envReducer,
  budgetRAODReducer,
  budgetRAPALReducer,
  budgetSummaryReducer,
  wfStatsReducer,
  budgetLedgerReducer,
  portalReducer,
  sl2Reducer,
  endingInventoryReducer,
  cashierLogsReducer,
  prAnnualSummaryReducer,
  trtnMonthPayReducer,
  jvTransReducer,
  gjReducer,
  saaoReducer,
  whTransferReducer,
  brTransferReducer,
  checkRegisterReducer,
  cashflowLedgerReducer,
  stockImageReducer
} from './slices';

const rootReducer = combineReducers({
  advanceDashboard: advanceDashboardReducer,
  aging: agingReducer,
  announcement: announcementReducer,
  auth: authenticationReducer,
  bizMeta: bizMetaReducer,
  bizState: bizStateReducer,
  budgetLedger: budgetLedgerReducer,
  budgetRAOD: budgetRAODReducer,
  budgetRAPAL: budgetRAPALReducer,
  budgetSummary: budgetSummaryReducer,
  cashPosition: cashPositionReducer,
  customReport: customReportReducer,
  daloy: daloyReducer,
  dashboard: dashboardReducer,
  dashboardCache: dashboardCacheReducer,
  endingInventory: endingInventoryReducer,
  env: envReducer,
  fs: fsReducer,
  gl: glReducer,
  global: globalStateReducer,
  imbal: imbalanceReducer,
  jepts: jePtsReducer,
  jeptssummary: jePtsSummaryReducer,
  matureChecks: matureChecksReducer,
  orderSummary: orderSummaryReducer,
  portal: portalReducer,
  postingSummary: postingSummaryReducer,
  preferences: preferencesReducer,
  prAnnualSummary: prAnnualSummaryReducer,
  privileges: privilegesReducer,
  purchaseSummary: purchaseSummaryReducer,
  registrationReducer,
  registry: registryReducer,
  salesSummary: salesSummaryReducer,
  setup: setupReducer,
  sc: scReducer,
  sl: slReducer,
  sl2: sl2Reducer,
  slSubBalances: slSubBalancesReducer,
  advSLSubBalances: advSLSubBalancesReducer,
  sop: sopReducer,
  storedValues: storedValuesReducer,
  sos: sosReducer,
  specialJournals: specialJournalsReducer,
  transactions: transactionReducer,
  trtnMonthPay: trtnMonthPayReducer,
  wfAdmin: wfAdminReducer,
  wfAssigned: wfAssignedReducer,
  wfStats: wfStatsReducer,
  cashierLogs: cashierLogsReducer,
  jvTrans: jvTransReducer,
  gj: gjReducer,
  saao: saaoReducer,
  whTransfer: whTransferReducer,
  brTransfer: brTransferReducer,
  checkRegister: checkRegisterReducer,
  cashflowLedger: cashflowLedgerReducer,
  stockImage: stockImageReducer
});

const persistConfig = {
  key: 'root',
  storage: localforage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export const persistor = persistStore(store);
