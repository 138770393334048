import RadztechLayout from 'src/layouts/RadztechLayout';
import NotFoundView from 'src/views/errors/NotFoundView';
import GuestPortal from 'src/views/GuestPortal/GuestPortal';
import ContactUs from 'src/views/landingPage/ContactUs/ContactUs';
import Radztech from 'src/views/landingPage/Radztech';
import Header from 'src/views/landingPage/Radztech/Header';

const radztechGuestRoutes = [
  {
    path: '/',
    element: RadztechLayout,
    children: [
      { path: '/', element: Radztech },
      {
        path: 'contact-us',
        element: ContactUs
      },
      {
        path: 'guest-portal/:cdBiz/:cdPortal/:ixJCd',
        element: GuestPortal
      }
    ]
  },
  {
    path: 'bg',
    element: Header
  },
  {
    path: '*',
    element: NotFoundView
  }
];

export default radztechGuestRoutes;
