import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  Tooltip,
  Popover,
  Button,
  Zoom
} from '@material-ui/core';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import DarkIcon from '@material-ui/icons/Brightness4';
import LightIcon from '@material-ui/icons/Brightness7';
import InputIcon from '@material-ui/icons/Input';
import SelectBizMenu from './SelectBizMenu';
import { logout } from 'src/redux/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, AccountCircle } from '@material-ui/icons';
import useMenu from 'src/hooks/useMenuV2';
import usePreferences from 'src/hooks/usePreferences';
import useBizState from 'src/hooks/useBizState';
import { reSelectBIZ } from 'src/redux/slices/auth';
import useRequest from 'src/hooks/useRequest';
import useNotif from 'src/hooks/useNotif';
import { NAVBAR_HEIGHT } from 'src/constants';
import { useEnv } from 'src/hooks';
import { useCashierLog } from 'src/components/CashierLog/context/CashierLogProvider';
import { clear as clearCashierLog } from 'src/redux/slices/cashierLogs';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.background.navbar,
    boxShadow: theme.shadows[5]
  },
  avatar: props => props?.logo_settings || {},
  appTitle: {
    paddingLeft: 8,
    color: theme.palette.primary.contrastText
  },
  selectBiz: {
    cursor: 'pointer'
  },
  toolbar: {
    height: NAVBAR_HEIGHT
  }
}));

const TopBar = ({ className, isSidebarOpen, toggleSidebar, ...rest }) => {
  const { CONFIG } = useEnv();
  const classes = useStyles({ logo_settings: CONFIG.logo_settings });
  const dispatch = useDispatch();
  const notify = useNotif();
  const request = useRequest();
  const { save: saveBizState } = useBizState();
  const { setDefaultTheme, changeMode, preferences } = usePreferences();
  const { mustSelectBiz, current_user } = useSelector(state => state.auth);
  const [userMenuAnchor, openUserMenu, closeUserMenu] = useMenu();
  const { cashierLog, bizSettings, logOff } = useCashierLog();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setDefaultTheme();
    saveBizState();
    const req = await request.post('/logout');
    dispatch(logout());
  };

  const selectBiz = () => {
    navigate('/app/dashboard');
    saveBizState();
    setDefaultTheme();
    dispatch(reSelectBIZ());
    return;
  };

  const toggleDarkMode = () => {
    if (preferences.mode === 'light') changeMode('dark');
    else changeMode('light');
  };

  const forceCloseCashierLog = () => {
    if (cashierLog.jid === 0) return;

    if (cashierLog.username === '' || (current_user?.username ?? '') === '') {
      return;
    }

    if (!(bizSettings?.users ?? []).includes(current_user?.username)) return;

    if (
      current_user?.username !== cashierLog.username &&
      cashierLog.reOpen === false
    ) {
      dispatch(clearCashierLog());
    }
  };

  useEffect(() => {
    forceCloseCashierLog();
  }, [cashierLog.username, cashierLog.jid, current_user?.username]);

  return (
    <>
      <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
        <Toolbar className={classes.toolbar}>
          <Box display="flex" alignItems="center">
            <Box
              component={RouterLink}
              to="/"
              display="flex"
              alignItems="center"
              style={{ textDecoration: 'none' }}
            >
              <Avatar src={CONFIG.logo_url.small} className={classes.avatar} />
              <Hidden mdDown>
                <Typography
                  className={classes.appTitle}
                  align="center"
                  variant="h4"
                >
                  {CONFIG.name.first_word}
                  <strong>{CONFIG.name.second_word}</strong> -
                </Typography>
              </Hidden>
            </Box>
            <SelectBizMenu />
          </Box>

          <Box flexGrow={1} />

          <IconButton color="inherit" onClick={toggleDarkMode}>
            {preferences.mode === 'light' ? <LightIcon /> : <DarkIcon />}
          </IconButton>

          {/* Large Screen */}
          <Hidden mdDown>
            {mustSelectBiz && (
              <Tooltip title="Select Biz">
                <IconButton color="inherit" onClick={selectBiz}>
                  <BusinessOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="UlapBiz Support">
              <IconButton component={RouterLink} color="inherit" to="/support">
                <ContactSupportOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton color="inherit" onClick={handleLogout}>
                <InputIcon />
              </IconButton>
            </Tooltip>
          </Hidden>

          {/* Small Screen */}
          <Hidden lgUp>
            <Popover
              style={{ marginLeft: '2em' }}
              open={Boolean(userMenuAnchor)}
              onClose={closeUserMenu}
              anchorEl={userMenuAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              TransitionComponent={Zoom}
            >
              <Box
                p={1}
                display="flex"
                flexDirection="column"
                minWidth={150}
                alignItems="flex-start"
              >
                {mustSelectBiz && (
                  <Button
                    startIcon={<BusinessOutlinedIcon />}
                    onClick={selectBiz}
                  >
                    Select Biz
                  </Button>
                )}
                <Button
                  startIcon={<ContactSupportOutlinedIcon />}
                  component={RouterLink}
                  to="/support"
                >
                  UlapBiz Support
                </Button>
                <Button startIcon={<InputIcon />} onClick={handleLogout}>
                  Logout
                </Button>
              </Box>
            </Popover>
            <IconButton color="inherit" onClick={openUserMenu}>
              <AccountCircle />
            </IconButton>
            <IconButton color="inherit" onClick={toggleSidebar}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  toggleSidebar: PropTypes.func
};

export default TopBar;
