import { useSelector } from 'react-redux';
import useJNTApi from './useJNTApi';

function useCourierService() {
  const { frontEnd } = useSelector(({ bizMeta }) => bizMeta);
  const jnt = useJNTApi();

  /**
   * Creates a new order using the specified courier service.
   * @param {string} [type=''] - The type of courier service to use for the order.
   * @param {Object} data - The order details to be sent to the courier service.
   * @returns {Promise<Object>} - The response object indicating the success status and any explanations for errors.
   */
  async function createOrder(type = '', data) {
    const map = {
      jnt: jnt.createOrder
    };

    if (!Boolean(map?.[type])) {
      return {
        success: false,
        explanation: 'Courier not yet supported'
      };
    }

    return await map[type](data);
  }

  /**
   * Tracks the given order with the specified courier service.
   * @param {string} [type=''] - The type of courier service to use for tracking the order.
   * @param {Object} [data=''] - The order details required for the tracking process.
   * @returns {Promise<Object>} - The response object indicating the success status and any explanations for errors.
   */
  async function trackOrder(type = '', data = '') {
    const map = {
      jnt: jnt.trackOrder
    };

    if (!Boolean(map?.[type])) {
      return {
        success: false,
        explanation: 'Courier not yet supported'
      };
    }

    return await map[type](data);
  }

  /**
   * Retrieves the order details for the given order with the given courier service.
   * @param {string} [type=''] - The type of courier service to use.
   * @param {Object} [data={}] - The order details to be generate.
   * @param {Object} [config={}] - The configuration object for the courier service.
   * @returns {Promise<Object>} - The response object from the API.
   */
  async function getOrderDetails(type = '', data = {}, config = {}) {
    const map = {
      jnt: jnt.getOrderDetails
    };

    if (!Boolean(map?.[type])) {
      return {
        success: false,
        explanation: 'Courier not yet supported'
      };
    }

    return await map[type](data, { ...config, ...(config?.[type] ?? {}) });
  }

  function getCourierSetup() {
    return (
      frontEnd?.api?.courier ?? {
        trans: {},
        sub_map: {},
        providers: {}
      }
    );
  }

  /**
   * Validates the setup of the courier service for a given transaction.
   * @param {Object} [jv={}] - The transaction object containing the necessary details for validation.
   * @returns {Object} - An object indicating the success status of the validation and an explanatory message.
   * If the setup is invalid, the message will indicate the specific issue:
   * - 'Courier service is not yet configured for this transaction' if the transaction does not have a configured courier service.
   * - 'Courier service field is unknown for this transaction.' if the courier service field is missing.
   * - 'Courier is required.' if the courier field is not provided in the transaction.
   * - 'Mapping of courier service is not properly configured.' if the courier mapping is incorrect.
   * - 'Waybill no already created.' if a waybill number has already been created.
   * If the setup is valid, the message will be 'Success'.
   */
  function validateSetup(jv = {}) {
    const ixJCd = jv?.ixJCd ?? 0;

    const { trans = {}, sub_map = {} } = getCourierSetup();

    if (!Boolean(trans?.[ixJCd])) {
      return {
        success: false,
        msg: 'Courier service is not yet configured for this transaction'
      };
    }

    if (!Boolean(trans?.[ixJCd]?.courier)) {
      return {
        success: false,
        msg: 'Courier service field is unknown for this transaction.'
      };
    }

    const courier_fld = jv?.[trans?.[ixJCd]?.courier] ?? 0;
    if (!Boolean(courier_fld)) {
      return {
        success: false,
        msg: 'Courier is required.'
      };
    }

    const courier = sub_map?.[courier_fld];
    if (!Boolean(courier)) {
      return {
        success: false,
        msg: 'Mapping of courier service is not properly configured.'
      };
    }

    const courier_settings = trans?.[ixJCd]?.[courier] ?? { mailno: '' };
    const kvs = jv?.kvs ?? [];

    if (kvs.find(k => k.key === courier_settings?.mailno && k.value !== '')) {
      return {
        success: false,
        msg: 'Waybill no already created.'
      };
    }

    return {
      success: true,
      msg: 'Success'
    };
  }

  return {
    createOrder,
    trackOrder,
    getOrderDetails,
    getCourierSetup,
    validateSetup
  };
}

export default useCourierService;
