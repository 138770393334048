import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CircularProgress, Container, Grid, LinearProgress, makeStyles, Typography, withStyles } from '@material-ui/core';
import { useNotif } from 'src/hooks';
import { Request } from 'src/helpers';
import { orange } from '@material-ui/core/colors';
import { DebounceTextField, Field, Loading } from 'src/components';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
    },
    container: {
        marginTop: theme.spacing(3)
    },
    title: {
        fontSize: '3rem',
        fontWeight: 900
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[300]
        }
    }
}))(Button);

const GuestPortal = () => {
    const [sourceParams] = useSearchParams();
    const classes = useStyles();
    const { error, success } = useNotif();
    const { ixJCd, cdBiz, cdPortal } = useParams();
    const [guestTransaction, setGuestTransaction] = useState();
    const [guestTransactionMeta, setGuestTransactionMeta] = useState();
    const [isLoading, setLoading] = useState();
    const [payload, setPayload] = useState({});
    const [KVS, setKVS] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false)

    const isVisible = fld => guestTransactionMeta?.flds?.includes(fld);

    const req = new Request('/api', {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const fetch = async (cancelCallback) => {
        setLoading(true);
        const jcdMetaRes = await req.get(`/portal/guest/${cdBiz}/${cdPortal}/meta`, {}, cancelCallback
        );
        const transMetaRes = await req.post(`/portal/guest/${cdBiz}/${cdPortal}/trans/${jcdMetaRes.data?.transactions[0]?.ixJCd || ixJCd}/meta`, {}, {}, cancelCallback);

        if (!jcdMetaRes.success || !transMetaRes.success) {
            error('Something went wrong');
            setLoading(false);
            return;
        }

        setGuestTransaction(jcdMetaRes.data.transactions[0]);
        setGuestTransactionMeta(transMetaRes.data);

        setLoading(false);
    }

    const onChangeField = (e) => {
        setPayload({
            ...payload,
            [e.target.name]: e.target.value
        })
    };

    const onChangeKVS = (e) => {
        setKVS({
            ...KVS,
            [e.target.name]: e.target.value
        })
    }

    const isFieldEmpty = (key) => {

        if (isSubmitted) {
            if (Object.entries(payload || {}).length !== 0 && key in payload && payload[key] !== "")
                return false
            else
                return true
        }

    }

    const onSubmit = async () => {

        setIsSubmitted(true);
        setLoading(true);
        const res = await req.post(`/portal/guest/${cdBiz}/${cdPortal}/trans/${guestTransaction?.ixJCd}/save`, {
            ...payload,
            kvs: {
                ...KVS,
                source: sourceParams.get('ref')
            }
        });

        if (!res.success) {
            error('Something went wrong')
            setErrors(res.error?.data || {});
            setLoading(false);
            return;
        }

        setLoading(false);
        setIsSubmitted(false);
        success('Success');
        setPayload({});
    }

    useEffect(() => {

        let cancel = () => { };
        fetch(c => (cancel = c));
        return cancel;
    }, [])


    if (isLoading)
        return <Loading />



    return (
        <Container className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <Box borderLeft={5} borderColor="primary.main" pl={1} my={2}>
                                        <Typography variant="h1" color="textSecondary">
                                            Message Us
                                        </Typography>
                                    </Box>
                                </Grid>
                                {isVisible('Name') && (
                                    <Grid item xs={12}>
                                        <Field
                                            onChangeField={onChangeField}
                                            data={{
                                                disabled: isLoading,
                                                caption: guestTransactionMeta?.labels?.Name ?? 'Name',
                                                value: payload["Particulars"],
                                                fld: 'Name',
                                                type: "str",
                                                error: isFieldEmpty('Name'),
                                                // errorMessage: <Errors errors={Object.entries(errors?.validation_errors || {}).length !== 0 ? errors?.validation_errors[key] : []} />
                                            }}
                                        />
                                    </Grid>
                                )}

                                {Object.entries(guestTransactionMeta?.kvs || {}).map(([key, value]) => (
                                    key !== "source" && <Grid item xs={12} key={key}>
                                        <Field
                                            key={key}
                                            onChangeField={onChangeKVS}
                                            data={{
                                                disabled: isLoading,
                                                caption: value?.caption,
                                                value: payload[key],
                                                fld: key,
                                                type: value?.type,
                                                error: isFieldEmpty(key)
                                                // error: Boolean(Object.entries(errors?.validation_errors || {}).length !== 0 && errors?.validation_errors[key]),
                                                // errorMessage: <Errors errors={Object.entries(errors?.validation_errors || {}).length !== 0 ? errors?.validation_errors[key] : []} />
                                            }}
                                        />
                                    </Grid>
                                ))}

                                {isVisible('Particulars') && (
                                    <Grid item xs={12}>
                                        <Field
                                            onChangeField={onChangeField}
                                            data={{
                                                disabled: isLoading,
                                                caption: guestTransactionMeta?.labels?.Particulars ?? 'Particulars',
                                                value: payload['Particulars'],
                                                fld: 'Particulars',
                                                type: "str",
                                                isLongText: true,
                                                error: isFieldEmpty('Particulars'),
                                                // errorMessage: <Errors errors={Object.entries(errors?.validation_errors || {}).length !== 0 ? errors?.validation_errors[key] : []} />
                                            }}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={3}>
                                    <ColorButton
                                        type="submit"
                                        disabled={isLoading}
                                        size="large"
                                        fullWidth
                                        onClick={onSubmit}
                                        endIcon={
                                            isLoading ? (
                                                <CircularProgress
                                                    size={15}
                                                    color="secondary"
                                                />
                                            ) : null
                                        }>
                                        Send
                                    </ColorButton>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default GuestPortal