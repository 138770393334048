import Home from "./Web";
import Faqs from "./Faqs/Faqs";
import BarDaGoalan from "./Gallery/Reports";
import Orrientation from "./Gallery/Orrientation";
import DeploMoment from "./Gallery/DeploMoment";
import Client from "./Gallery/Client";
import Food from "./Gallery/Food";
import BibleStudy from "./Gallery/BibleStudy";
import Birthday from "./Gallery/Birthday";
import FunMoment from "./Gallery/FunMoment";

const routes = [
  {
    path: "/",

    children: [
      {
        path: "/",
        element: Home,
      },
      {
        path: "faqs",
        element: Faqs,
      },
      {
        path: "Reporting",
        element: BarDaGoalan,
      },
      {
        path: "Orrientation",
        element: Orrientation,
      },
      {
        path: "DeploMoment",
        element: DeploMoment,
      },
      {
        path: "Client",
        element: Client,
      },
      {
        path: "Food",
        element: Food,
      },
      {
        path: "BibleStudy",
        element: BibleStudy,
      },
      {
        path: "Birthday",
        element: Birthday,
      },
      {
        path: "FunMoment",
        element: FunMoment,
      },
    ],
  },
];

export default routes;
