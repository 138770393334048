/**
 *
 * @param {object} param
 * @param {object} param.obj
 * @param {string} param.property
 */
function updateNestedObj(obj = {}, property = '', value) {
  const objNest = property.split('.');
  const lastProp = objNest.pop();

  let ref = obj;

  for (const prop of objNest) {
    if (!ref.hasOwnProperty(prop)) ref[prop] = {};
    ref = ref[prop];
  }

  ref[lastProp] = value;

  return obj;
}

export default updateNestedObj;
