import { createSlice } from '@reduxjs/toolkit';
import { updateNestedObj } from 'src/utils';

const slice = createSlice({
  name: 'advanceDashboard',
  initialState: {},
  reducers: {
    setReportFilter(state, { payload: { property, value } }) {
      updateNestedObj(state, property, value);
    },
    clear() {
      return {};
    },
    restore(_, action) {
      return action.payload || {};
    },
    override(_, action) {
      return action.payload || {};
    }
  }
});

const advanceDashboardReducer = slice.reducer;

export const { restore, clear, setReportFilter } = slice.actions;
export default advanceDashboardReducer;
