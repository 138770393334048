import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  ixBrch: 0,
  ixRC: 0,
  sRC: '',
  dt1: null,
  dt2: null,
  textValue: '',
  tab: 0,
  res: []
};

const slice = createSlice({
  name: 'saao',
  initialState: INITIAL_STATE,
  reducers: {
    setBranch(state, action) {
      state.ixBrch = action.payload;
    },
    setDateRange(state, action) {
      state.dt1 = action.payload.dt1;
      state.dt2 = action.payload.dt2;
      state.textValue = action.payload.textValue;
    },
    setData(state, action) {
      state.res = action.payload.res;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setRC(state, action) {
      state.ixRC =
        action.payload.ixSub === '' || action.payload.ixSub === null
          ? 0
          : action.payload.ixSub;
      state.sRC = action.payload.sSub;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const saaoReducer = slice.reducer;

export const {
  setBranch,
  setData,
  setDateRange,
  setRC,
  clear,
  restore,
  setTab
} = slice.actions;
export default saaoReducer;
