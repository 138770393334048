import React, { useState, useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { useToggle } from 'src/hooks';

const emojiWidth = 30;

const useStyles = makeStyles({
  emojiContainer: {
    position: 'absolute',
    right: 16,
    bottom: 4,
    borderRadius: 50,
    display: 'flex',
    height: 30,
    overflow: 'hidden',
    justifyContent: 'flex-end',
    transition: 'width 300ms',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0 0 0 1px #d0d7de, 0 0 0 #d0d7de, 0 1px 1px #d0d7de'
    }
  },
  emojiWrap: {
    height: 30,
    width: emojiWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  emojiButton: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    transition: 'font-size 300ms',
    width: emojiWidth,
    '&:hover': {
      fontSize: '1.2rem'
    }
  }
});

const emojis = ['🧡', '😮', '😊', '😁', '👏', '💔', '👎', '👍'];

const MDEmoji = ({ value, onChange, save = () => { }, ...otherProps }) => {
  const classNames = useStyles();
  const [width, setWidth] = useState(1);
  const ref = useRef(null);
  const [onFullScreen, , , toggleFullScreen] = useToggle();

  const onEmojiCLick = emoji => {
    const textarea = ref.current.textarea;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    onChange(value.substring(0, start) + emoji + value.substring(end));

    setTimeout(() => {
      textarea.focus();
      textarea.selectionStart = start + emoji.length;
    }, 10);
  };

  function handleKeydown(e) {
    if (e.shiftKey && e.key === 'Enter') return;
    if (e.ctrlKey && e.key === 'Enter') return;

    if (e.key === 'Enter') {
      e.preventDefault();
      save();
    }

    if ((e.shiftKey || e.ctrlKey) && e.key === 'Enter' && !onFullScreen) {
      e.preventDefault();
      save();
    }
  }

  return (
    <Box position="relative">
      <MDEditor
        value={value}
        onChange={onChange}
        highlightEnable={false}
        extraCommands={[
          commands.codeEdit,
          commands.codeLive,
          commands.codePreview,
          commands.divider,
          {
            ...commands.fullscreen,
            execute: (...args) => {
              toggleFullScreen();
              commands.fullscreen.execute(...args);
            }
          }
        ]}
        onKeyDown={handleKeydown}
        {...otherProps}
        ref={ref}
      />
      <Box
        width={width * emojiWidth}
        onMouseOver={() => setWidth(emojis.length)}
        onMouseLeave={() => setWidth(1)}
        className={classNames.emojiContainer}
      >
        {emojis.map((item, index) => (
          <Box key={index}>
            <button
              className={classNames.emojiButton}
              onClick={() => onEmojiCLick(item)}
            >
              {item}
            </button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MDEmoji;
