import React from "react";
import { ThemeProvider } from "@material-ui/core";
import createRouter from "./helpers/createRouter";
import routes from "./routes";
import defaultTheme from "./theme";

const router = createRouter(routes);

function InternsBatch6 () {
  return <ThemeProvider theme={defaultTheme}>{router}</ThemeProvider>;
}
  
export default InternsBatch6;
