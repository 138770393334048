import { isFunction } from 'lodash';

/**
 *
 * @param {object} param
 * @param {column} param.col
 * @param {dataT} param.row
 * @param {string|number} param.id
 * @param {string|number} param.value
 * @param {string} param.field
 * @param {boolean} param.hasFocus
 */
function getCellValue({ col, row, id, value, field, hasFocus }) {
  let parsedVal = value;

  if (col.hasOwnProperty('valueGetter') && isFunction(col.valueGetter))
    parsedVal = col.valueGetter({ row, id, value, field, hasFocus });

  if (col.hasOwnProperty('valueFormatter') && isFunction(col.valueFormatter))
    parsedVal = col.valueFormatter({
      row,
      id,
      value: parsedVal,
      field,
      hasFocus
    });

  if (col.hasOwnProperty('renderCell') && isFunction(col.renderCell))
    parsedVal = col.renderCell({ row, id, value: parsedVal, field, hasFocus });

  return parsedVal;
}

export default getCellValue;
