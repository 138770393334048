import React, {
  Suspense,
  useContext,
  createContext,
  useRef,
  useState
} from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Loading from 'src/components/Loading';
import { NAVBAR_HEIGHT } from 'src/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSideBarOpen } from 'src/redux/slices/global';
import RightDrawer from 'src/components/RightDrawer';
import { Announcement } from 'src/components';
import StickyBanner from 'src/components/StickyBanner';
import { useToggle } from 'src/hooks';
import { TransSearchProvider } from 'src/contexts';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: NAVBAR_HEIGHT
  },
  contentContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayoutContext = createContext({});

const DashboardLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isStickyBannerOpen, showBanner, hideBanner] = useToggle();
  const [stickyBannerDetails, setStickyBannerDetails] = useState({
    title: '',
    detail1: '',
    detail2: '',
    detail3: '',
    detail4: '',
    detail5: ''
  });
  const { isSidebarOpen } = useSelector(state => state.global);
  const contentRef = useRef(null);

  function closeSidebar() {
    dispatch(setIsSideBarOpen(false));
  }

  function toggleSidebar() {
    dispatch(setIsSideBarOpen(!isSidebarOpen));
  }

  return (
    <DashboardLayoutContext.Provider
      value={{
        contentRef,
        isStickyBannerOpen,
        stickyBannerDetails,
        setStickyBannerDetails,
        showBanner,
        hideBanner
      }}
    >
      <TransSearchProvider>
        <div className={classes.root}>
          <RightDrawer>
            <TopBar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
            <NavBar
              isSidebarOpen={isSidebarOpen}
              closeSidebar={closeSidebar}
              toggleSidebar={toggleSidebar}
            />

            <div className={classes.wrapper}>
              <div className={classes.contentContainer}>
                <Announcement />
                <StickyBanner />
                <div className={classes.content} ref={contentRef}>
                  <Suspense fallback={<Loading />}>
                    <Outlet />
                  </Suspense>
                </div>
              </div>
            </div>
          </RightDrawer>
        </div>
      </TransSearchProvider>
    </DashboardLayoutContext.Provider>
  );
};

export default DashboardLayout;
export const useDashboardLayout = () => useContext(DashboardLayoutContext);
