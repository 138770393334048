import React from "react";
import { Typography } from "@material-ui/core";
import img1 from "./image/img1.png";
import img2 from "./image/img2.png";
import img3 from "./image/img3.png";
import img4 from "./image/img4.png";
import img5 from "./image/img5.png";

const LandingPageSelfieLogin = () => {
  return (
    <div style={{backgroundColor: "white"}}>
      <Typography variant="h2">
      {/* page 1 */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          alignItems: "center",
          gap: "5rem",
          padding: "5rem 5rem",
        }}
      >
        <div>
          <h1
            style={{
              fontSize: "4.3rem",
              fontWeight: "1000",
              marginBottom: "3rem",
            }}
          >
            Secure Your Shift in a{" "}
            <span style={{ color: "#FF7704" }}>Selfie</span> Style
          </h1>

          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "3rem",
            }}
          >
            Redefine how your employees manage their time with our newest
            feature, Selfie Log-in!
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "2rem",
            }}
          >
            Level up your biometrics with a seamless and secure method of
            clocking in and out.
          </p>
          <button
            style={{
              padding: "15px 70px",
              borderRadius: "25px",
              backgroundColor: "#FF7704",
              color: "white",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            TRY IT NOW
          </button>
        </div>
        <img
          src={img1}
          style={{
            width: "600px",
            height: "auto",
          }}
        />
      </div>

      {/* page 2 */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          alignItems: "center",
          padding: "1rem 5rem",
          marginBottom: "5rem"
        }}
      >
        <img
          src={img2}
          style={{
            marginLeft: "5rem",
            width: "400px",
            height: "auto",
          }}
        />
        <div>
          <h1
            style={{
              fontSize: "4.3rem",
              fontWeight: "1000",
              marginBottom: "2rem",
            }}
          >
            <span style={{ color: "#FF7704" }}>It's Easy</span> as a Selfie!
          </h1>
          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "2rem",
            }}
          >
            Clocking in and out has never been simpler!
          </p>{" "}
          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "2rem",
            }}
          >
            Just login the UlapPayroll app, take a selfie, and you're clocked
            in! When it's time to go home, repeat the process to clock out.{" "}
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "2rem",
            }}
          >
            With Selfie Log-In, you can enjoy peace of mind knowing your time is
            accurately recorded.{" "}
          </p>
        </div>
      </div>

      {/* page 3 */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          alignItems: "center",
          padding: "1rem 5rem",
          marginBottom: "5rem"
        }}
      >
        <div>
          <h1
            style={{
              fontSize: "4.3rem",
              fontWeight: "1000",
              marginBottom: "3rem",
              marginRight: "1rem"
            }}
          >
            Stay in Bounds with
            <span style={{ color: "#FF7704" }}> Geotagging </span>
          </h1>
          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "2rem",
            }}
          >
            Tired of inaccurate timecards and unauthorized absences? It's time to take control with our Geotagging feature.
            {" "}
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "2rem",
            }}
          >
            Our advanced geotagging technology ensures that your employees are clocking in and out from approved work locations.             {" "}
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "2rem",
            }}
          >
            By defining specific work zones, you can effectively monitor employee whereabouts and prevent time theft.          
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "2rem",
            }}
          >
            Say goodbye to timecard fraud and hello to accurate, reliable time tracking.
          </p>
          
        </div>
        <img
          src={img3}
          style={{
            marginLeft: "5rem",
            width: "550px",
            height: "auto",
          }}
        />
      </div>

      {/* page 4 */}
      <div
          style={{
            padding: "1rem 5rem",
            marginBottom: "5rem"
          }}> 
          <h1
            style={{
              fontSize: "4rem",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
          Experience the Selfie Log-in <span style={{ color: "#FF7704" }}> Advantage</span>         
          </h1>
          <p
            style={{
              fontSize: "1.2rem",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
          Say goodbye to forgotten time cards and long queues. Our selfie log-in feature offers a seamless and efficient way to track your employees' hours.          
          </p>
          <img
          src={img4}
          style={{
            display: "block",
            margin: "0 auto",
            width: "auto",
            height: "300px",
          }}
        />
      </div>

    {/* page 5 */}
    <div
      style={{
        padding: "1rem 5rem",
        display: "flex",           
        flexDirection: "column",   
        alignItems: "center",      
        textAlign: "center",  
        gap: "0.5rem"    
      }}> 
       <h3 textAlign = "center">DISCOVER THE <span style={{ color: "#FF7704"}}>PERFECT PLANS</span> FOR</h3>
    <h1             
    style={{
      fontSize: "4.3rem",
      fontWeight: "1000",
      marginBottom: "1rem",
      marginRight: "1rem",
      textAlign: "center"
    }}>
    YOUR <span style={{ color: "#FF7704" }}>PAYROLL NEEDS</span></h1>
    <p style={{textAlign: "center"}}>Choose from these three flexible and budget-friendly options tailored to your needs.</p>
    <p style={{textAlign: "center", marginBottom: "0.5rem"}}>For as low as XXX PHP, enjoy the convenience and security of our selfie log-in with integrated geotagging.</p>
    <img
          src={img5}
          style={{
            display: "block",
            margin: "0 auto",
            width: "auto",
            height: "300px",
          }}
        />
    <p style={{textAlign: "center", fontWeight: "bold", marginTop: "0.5rem", marginBottom: "0.5rem"}}>Unsure which plans suits your payroll needs?</p>
    <button
            style={{
              padding: "15px 70px",
              borderRadius: "25px",
              backgroundColor: "#FF7704",
              color: "white",
              border: "none",
              fontSize: "18px",
              cursor: "pointer",
              fontWeight: "bold",
              marginBottom: "3rem"
            }}
          >
            Book A Demo
          </button>

    </div>
    <div>
      {""}
    </div>
    </Typography>
    </div>
  );
};

export default LandingPageSelfieLogin;
